.fc-col-header {
    background-color: #F0F0F3;
    border-radius: 10px 10px 0 0;

}
.fc-col-header th{
    border: none;
}
/*.fc td, .fc th {*/
/*    border-style: none !important;*/
/*}*/

/*.fc td, .fc th {*/
/*    border-style: double !important;*/
/*    border-width: 3px !important;*/
/*    border-color: #aaa !important;*/
/*}*/
.fc-right > button{
    border: none;
}
.time-line-content{
    height: 60px !important;
}
/*.fc-timegrid thead{*/
/*    */
/*}*/
:root {
    /*--fc-border-color: red;*/
    --fc-border-color: #DFDFE4;
    /*--fc-border-radius: 20px;*/
    /*--fc-daygrid-event-dot-width: 5px;*/
}
.fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 1.2rem 4px;
    font-size: .9rem;
    font-weight: 500;
}
/*POS*/
/*.fc .fc-col-header-cell-cushion::after {*/
/*    content: "POS";*/
/*    display: block;*/
/*    color: #CB444A;*/
/*    font-weight: 600;*/
/*}*/
.fc .fc-scrollgrid {
    border-radius: 10px;
    /*border: none;*/
}
.fc-theme-standard th {
    /*border-right: 2px solid red;*/
    /*border: none;*/
}