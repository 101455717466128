@import url("./font.css");

body {
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Lato", serif !important;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

