.notif-modal-title {
    width: 100%;
    text-align: center;
    margin-top: 100px;
    font-family: "Montserrat", serif;
}
.notif-modal-title h2{
    color: #231F20;
    font-size: 1.5rem;
    font-weight: 500;
}
.notif-modal-title p{
    color: #000;
    margin: 10px 0;
    padding: 0 50px;
    font-weight: 600;
}
.notif-modal-title h3{
    color: #231F20;
    font-size: 1.5rem;
    font-weight: 500;
}
.notif-modal-title .red{
    color: #CB444A;
    font-size: 1.5rem;
    font-weight: 500;
}
.close-icon{
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}