.notif-modal-title {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    font-family: "Montserrat", serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/*.notif-modal-title h2{*/
/*    color: #231F20;*/
/*    font-size: 1.5rem;*/
/*    font-weight: 600;*/
/*}*/
.select-wrapper {
    position: relative;
    font-family: "Montserrat", serif;
}
.select-wrapper select {
    font-family: "Montserrat", serif;
    border: none;
    border-radius: 30px;
    padding: 16px 30px;
    background-color: #F0F0F3;
    font-size: 13px;
    outline: none;
    margin-bottom: .6rem;
    width: 20rem;
    border-right: 2rem solid transparent;
}
/*.notif-modal-title .red{*/
/*    color: #CB444A;*/
/*    font-size: 1.5rem;*/
/*    font-weight: 600;*/
/*}*/
/*.close-icon{*/
/*    position: absolute;*/
/*    top: 1rem;*/
/*    right: 1rem;*/
/*    cursor: pointer;*/
/*}*/