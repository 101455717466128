/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-BlackItalic.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-BlackItalic.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-BlackItalic.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-BlackItalic.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-BlackItalic.svg#Montserrat-BlackItalic') format('svg');*/
/*    font-weight: 900;*/
/*    font-style: italic;*/

/*}*/

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-Bold.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-Bold.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-Bold.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-Bold.svg#Montserrat-Bold') format('svg');*/
/*    font-weight: bold;*/
/*    font-style: normal;*/

/*}*/

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-Black.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-Black.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-Black.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-Black.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-Black.svg#Montserrat-Black') format('svg');*/
/*    font-weight: 900;*/
/*    font-style: normal;*/

/*}*/

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-Italic.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-Italic.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-Italic.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-Italic.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-Italic.svg#Montserrat-Italic') format('svg');*/
/*    font-weight: normal;*/
/*    font-style: italic;*/

/*}*/

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-ExtraLightItalic.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraLightItalic.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraLightItalic.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic') format('svg');*/
/*    font-weight: 200;*/
/*    font-style: italic;*/

/*}*/

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-ExtraBold.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraBold.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraBold.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');*/
/*    font-weight: bold;*/
/*    font-style: normal;*/

/*}*/

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-ExtraBoldItalic.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraBoldItalic.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraBoldItalic.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic') format('svg');*/
/*    font-weight: bold;*/
/*    font-style: italic;*/

/*}*/

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-ExtraLight.eot');
    src: url('./fonts/montserrat/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-ExtraLight.woff') format('woff'),
    url('./fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype'),
    url('./fonts/montserrat/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;

}

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-BoldItalic.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-BoldItalic.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-BoldItalic.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');*/
/*    font-weight: bold;*/
/*    font-style: italic;*/

/*}*/

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-SemiBold.eot');
    src: url('./fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('./fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'),
    url('./fonts/montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-SemiBold.eot');
    src: url('./fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('./fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'),
    url('./fonts/montserrat/Montserrat-SemiBold.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-Medium.eot');
    src: url('./fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-Medium.woff') format('woff'),
    url('./fonts/montserrat/Montserrat-Medium.ttf') format('truetype'),
    url('./fonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;

}

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-MediumItalic.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-MediumItalic.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-MediumItalic.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');*/
/*    font-weight: 500;*/
/*    font-style: italic;*/

/*}*/

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-LightItalic.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-LightItalic.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-LightItalic.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-LightItalic.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-LightItalic.svg#Montserrat-LightItalic') format('svg');*/
/*    font-weight: 300;*/
/*    font-style: italic;*/

/*}*/

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-Regular.eot');
    src: url('./fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-Regular.woff') format('woff'),
    url('./fonts/montserrat/Montserrat-Regular.ttf') format('truetype'),
    url('./fonts/montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-Thin.eot');
    src: url('./fonts/montserrat/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat/Montserrat-Thin.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-Thin.woff') format('woff'),
    url('./fonts/montserrat/Montserrat-Thin.ttf') format('truetype'),
    url('./fonts/montserrat/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
    font-weight: 100;
    font-style: normal;

}

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-ThinItalic.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-ThinItalic.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-ThinItalic.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-ThinItalic.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-ThinItalic.svg#Montserrat-ThinItalic') format('svg');*/
/*    font-weight: 100;*/
/*    font-style: italic;*/

/*}*/

/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    src: url('../public/fonts/montserrat/Montserrat-SemiBoldItalic.eot');*/
/*    src: url('../public/fonts/montserrat/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),*/
/*    url('../public/fonts/montserrat/Montserrat-SemiBoldItalic.woff2') format('woff2'),*/
/*    url('../public/fonts/montserrat/Montserrat-SemiBoldItalic.woff') format('woff'),*/
/*    url('../public/fonts/montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype'),*/
/*    url('../public/fonts/montserrat/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic') format('svg');*/
/*    font-weight: 600;*/
/*    font-style: italic;*/
/*}*/

/* lato */

@font-face {
    font-family: 'Lato';
    src: url('./fonts/lato/Lato-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('./fonts/lato/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* open_sans */

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/open_sans/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/open_sans/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

