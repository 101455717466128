.search-form {
    color: #555;
    display: flex;
    padding: 2px;
    border: 1px solid #EAEAEA;
    border-radius: 20px;
    width: 220px;
    position: relative;
}
.search-form input[type="search"] {
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
}

.search-form input[type="search"]::placeholder {
    color: #bbb;
}
.search-form button[type="submit"] {
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    cursor: pointer;
    opacity: 0.7;
}

.search-form button[type="submit"]:hover {
    opacity: 1;
}
.search-form button[type="submit"]:focus,
.search-form input[type="search"]:focus {
    box-shadow: 0 0 3px 0 #F5F5FA;
    border-color: #F5F5FA;
    outline: none;
}